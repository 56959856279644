import {
  AuditOutlined,
  BugOutlined,
  FolderOpenOutlined,
  HighlightOutlined,
  PoweroffOutlined,
  SearchOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { isBdlAdmin, isBdlUser } from 'business/admin/services/permission';
import Routes from 'business/router/routes';
import {
  canUserAccessInvoicePage,
  canUserAccessSafranRequestPage,
} from 'business/user/services';
import authService from 'business/user/services/authService';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from 'ui/navBar/index.module.scss';

const useMenuDropDownItems = (user: UserWithPrivateInfos) => {
  const { t } = useTranslation();

  const disconnect = async () => {
    sessionStorage.clear();
    await authService.logout();
  };
  let items = [
    {
      key: 'profile',
      label: (
        <Link
          className={styles.menuItem}
          data-test-id="myProfileButton"
          to={Routes.Profile}
        >
          <UserOutlined />
          {t('common.profile')}
        </Link>
      ),
    },
  ];

  if (isBdlAdmin(user) || user.companyIds.length) {
    items = items.concat([
      {
        key: 'companies',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="companiesButton"
            to={Routes.Companies}
          >
            <ShopOutlined />
            {t('pages.companies.title')}
          </Link>
        ),
      },
    ]);
  }

  if (isBdlAdmin(user)) {
    items = items.concat([
      {
        key: 'bdl-users',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="bdlUsersButton"
            to={Routes.BdlUsers}
          >
            <TeamOutlined />
            {t('pages.admin.bdlUsers.title')}
          </Link>
        ),
      },
      {
        key: 'users',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="userButton"
            to={Routes.Users}
          >
            <TeamOutlined />
            {t('pages.admin.users.title')}
          </Link>
        ),
      },
      {
        key: 'logs',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="securityLogButton"
            to={Routes.Logs}
          >
            <BugOutlined />
            {t('common.securityLog')}
          </Link>
        ),
      },
      {
        key: 'patent-ops',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="patentOpsButton"
            to={Routes.OpsDocumentsPage}
          >
            <AuditOutlined />
            {t('pages.admin.patentOps.title')}
          </Link>
        ),
      },

      {
        key: 'archiveManagement',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="archiveManagementButton"
            to={Routes.ArchiveSubscriptions}
          >
            <FolderOpenOutlined />
            {t('common.archiveManagement')}
          </Link>
        ),
      },
    ]);
  }

  if (canUserAccessSafranRequestPage(user)) {
    items = items.concat([
      {
        key: 'requests',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="safranRequest"
            to={Routes.SafranExportQuery}
          >
            <SearchOutlined />
            {t('pages.exportQuery.title')}
          </Link>
        ),
      },
    ]);
  }

  if (canUserAccessInvoicePage(user)) {
    items = items.concat([
      {
        key: 'invoices',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="InvoiceButton"
            to={Routes.Invoices}
          >
            <AuditOutlined />
            {t('pages.invoices.title')}
          </Link>
        ),
      },
    ]);
  }
  if (
    (isBdlAdmin(user) && user.osrAccess === true) ||
    (isBdlUser(user) && user.osrAccess === true)
  ) {
    items = items.concat([
      // {
      //   key: 'renewTrademark',
      //   label: (
      //     <Link
      //       className={styles.menuItem}
      //       data-test-id="TrademarkOsr"
      //       to={Routes.TrademarkOsr}
      //     >
      //       <HighlightOutlined />
      //       OSR Marques
      //     </Link>
      //   ),
      // },
      {
        key: 'renewDesign',
        label: (
          <Link
            className={styles.menuItem}
            data-test-id="DesignOsr"
            to={Routes.DesignOsr}
          >
            <HighlightOutlined />
            OSR Dessins & Modèles
          </Link>
        ),
      },
    ]);
  }

  items = items.concat([
    {
      key: 'logout',
      label: (
        <div className={styles.logoutText} onClick={disconnect}>
          <PoweroffOutlined />
          {t('pages.common.logout')}
        </div>
      ),
    },
  ]);

  return items;
};

export default useMenuDropDownItems;
