import { Panel } from 'ui/description/types';
import { GetTrademarkLinkQuery } from 'generated/graphql';
import { getCountryLabel } from 'technical/countriesTranslation';
import LinkList from 'ui/linkList';
import StatusBadge from 'business/common/components/statusBadge';
import Routes from 'business/router/routes';
import { generatePath } from 'react-router';
import { formatDate, formatDateAndNumberString } from 'technical/date';
import { Trademark } from './types';
import { bdlOfficerAndSecretaryColumns } from 'business/resources/domain/components/panels';
import { TFunction } from 'react-i18next';

const getTrademarkDetails = (
  trademarksLink: GetTrademarkLinkQuery['trademarks'] | null,
  t: TFunction,
  trademark: Trademark | null,
): Panel<Trademark> => {
  return {
    protections: [
      {
        key: 'status',
        render: ({ status }) => <StatusBadge status={status} />,
      },
      {
        key: 'country',
        render: (record: Trademark) => {
          return (
            <div>
              <span>{getCountryLabel(record.country)}</span>
              <LinkList
                links={(trademarksLink ?? []).map((link) => {
                  return {
                    path: generatePath(Routes.TrademarkPage, {
                      id: link.id,
                    }),
                    label: getCountryLabel(link.country),
                  };
                })}
              />
            </div>
          );
        },
      },
      {
        key: 'frenchPolynesiaExtension',
        hideRow: (data) => !(data?.country?.code === 'FR'), // Display only for french countries
        render: (record: Trademark) => {
          return (
            <div>
              <span>
                {t('common.response', {
                  context:
                    record.frenchPolynesiaExtension &&
                    record.frenchPolynesiaExtension === 'Yes'
                      ? 'yes'
                      : 'no',
                })}
              </span>
            </div>
          );
        },
      },
      {
        key: 'protection',
        render: (data) => {
          if (data.protection?.protectionCode === 'MQ') {
            return `${data.protection?.protectionLabel} ${data.protection.routeLabel}`;
          }
          return `${data.protection?.protectionLabel}`;
        },
      },
      {
        key: 'trademarkClasses',
        hideRow: (data) => !data?.trademarkClasses?.length,
        render: (record: Trademark) => {
          const classes = record.trademarkClasses.map((d) => d.classCode);
          return classes
            .sort((a, b) => {
              return a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base',
              });
            })
            .join(' - ');
        },
      },
      {
        key: 'depositNumber',
        translationKey: 'firstDeposit',
        render: (data) =>
          formatDateAndNumberString({
            nbToString: data.depositNumber,
            date: new Date(data.depositDate),
          }),
      },
      {
        key: 'registrationNumber',
        translationKey: 'registration',
        hideRow: (d) => !(d?.firstRegistrationDate, d?.registrationNumber),
        render: ({
          firstRegistrationDate,
          lastRegistrationDate,
          registrationNumber,
        }) => {
          if (firstRegistrationDate) {
            return `${formatDate(
              new Date(firstRegistrationDate),
            )} | ${registrationNumber}`;
          }
          if (lastRegistrationDate) {
            return `${formatDate(
              new Date(lastRegistrationDate),
            )} | ${registrationNumber}`;
          }
          return null;
        },
      },
      {
        key: 'priorities',
        translationKey: 'priority',
        hideRow: (data) => !data?.priorities?.length,
        render: (data) =>
          formatDateAndNumberString({
            nbToString: `${data.priorities[0].country?.labelFr} | ${data.priorities[0]?.priorityNumber}`,
            date: new Date(data.priorities[0]?.priorityDate),
          }),
      },

      {
        key: 'expirationDate',
        hideRow: (data) => {
          return !!(data && data.expirationDate);
        },
      },
      {
        key: 'abandonmentDate',
        hideRow: (data) => {
          return !!(data && data.abandonmentDate);
        },
      },
    ],
    references: [
      {
        key: 'holder',
      },
      {
        key: 'effectiveHolder',
      },
      {
        key: 'clientRef',
        hideRow: (data) => !data?.clientRef,
      },
      {
        key: 'clientRef2',
        hideRow: (data) => !data?.clientRef2,
        render: (data) => {
          return data.clientRef2;
        },
      },
      {
        key: 'bdlRef',
        render: (data) => {
          return data?.misRef
            ? `${data?.bdlRef} (${data?.misRef})`
            : data?.bdlRef;
        },
      },
      {
        key: 'clientOfficer',
      },
      bdlOfficerAndSecretaryColumns(t, 'trademark', trademark),
    ],
  };
};

export default getTrademarkDetails;
