import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { Design } from 'business/resources/design/services/types';
import { ExtractRouteParams, generatePath, useParams } from 'react-router';

import EventTable from 'business/resources/shared/components/event/eventTable';
import ErrorOverlay from 'ui/errorOverlay';
import Routes from 'business/router/routes';
import { resourceExists } from 'business/common/services';
import HeaderTitles from 'ui/headerTitles';
import StatusBadge from 'business/common/components/statusBadge';
import { formatDateAndNumberString } from 'technical/date';
import { getCountryLabel } from 'technical/countriesTranslation';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import { Panel } from 'ui/description/types';
import { useGetDesign } from 'business/resources/design/services/useGetDesign';
import Carousel from 'ui/carousel';
import Spacer from 'ui/spacer';
import { useGetOfficialLinks } from 'business/resources/shared/services/links/useGetOfficialLink';
import { getDesignOfficialLinks } from 'business/common/services/officialRegister/getDesignOfficialLink';
import { DesignWithImages } from 'business/common/services/officialRegister/types';
import ResourcePanel from 'business/resources/shared/components/resourcePanel/resourcePanel';
import OfficalLinkButton from 'business/resources/shared/components/officialLinkButton';
import ResourceLinks from 'business/common/components/resourceLinks';
import { bdlOfficerAndSecretaryColumns } from 'business/resources/domain/components/panels';
import { TFunction, useTranslation } from 'react-i18next';
import { TabsProps } from 'antd';
import { useGetDesignEvents } from 'business/resources/design/services/useGetDesignEvents';
import { useDesignBreadcrumbs } from 'business/resources/design/services/useGetDesignBreadCrumbs';
import Timeline from 'ui/timeline';
import ResourceComment from 'business/resources/shared/components/comments/resourceCommentList';
import { useGetDesignLinks } from 'business/resources/design/services/useGetDesignLink';
import { GetDesignLinkQuery } from 'generated/graphql';
import LinkList from 'ui/linkList';
import { isBdlAdmin, isBdlUser } from 'business/admin/services/permission';
import { useLoggedInAppContext } from 'business/AppBootstrapper';

const getDesignDetails = (
  designLinks: GetDesignLinkQuery['designs'] | null,
  t: TFunction,
  design: Design | null,
  isBdlUserOrAdmin: boolean,
): Panel<Design> => {
  return {
    protections: [
      {
        key: 'status',
        render: ({ status }) => <StatusBadge status={status} />,
      },
      {
        key: 'country',
        render: (data: Design) => {
          return (
            <div>
              <span>{getCountryLabel(data.country)}</span>
              <LinkList
                links={(designLinks ?? []).map((link) => {
                  return {
                    path: generatePath(Routes.DesignPage, {
                      id: link.id,
                    }),
                    label: getCountryLabel(link.country),
                  };
                })}
              />
            </div>
          );
        },
      },
      {
        key: 'protection',
        render: (data) => {
          if (data.protection?.protectionCode === 'MQ') {
            return `${data.protection?.protectionLabel} ${data.protection.routeLabel}`;
          }
          return `${data.protection?.protectionLabel}`;
        },
      },
      {
        key: 'depositNumber',
        translationKey: 'deposit',
        render: (data) =>
          formatDateAndNumberString({
            nbToString: data.depositNumber,
            date: new Date(data.depositDate),
          }),
      },
      {
        key: 'registrationNumber',
        translationKey: 'registration',
        hideRow: (d) => !(d?.registrationDate && d.registrationNumber),
        render: ({ registrationDate, registrationNumber }) => {
          return formatDateAndNumberString({
            nbToString: registrationNumber,
            date: new Date(registrationDate),
          });
        },
      },

      {
        key: 'abandonmentDate',
        hideRow: (data) => !!data?.abandonmentDate,
      },
    ],
    references: [
      {
        key: 'holder',
      },
      {
        key: 'effectiveHolder',
      },
      {
        key: 'clientRef',
      },
      {
        key: 'clientRef2',
        hideRow: (data) => !data?.clientRef2,
        render: (data) => {
          return data.clientRef2;
        },
      },
      {
        key: 'bdlRef',
        render: (data) => {
          return data?.misRef
            ? `${data?.bdlRef} (${data?.misRef})`
            : data?.bdlRef;
        },
      },
      {
        key: 'clientOfficer',
      },
      {
        key: 'processAgentName',
        hideRow: (d) => !d?.processAgentName,
        render: (d) => {
          if (isBdlUserOrAdmin) {
            return <span>{d.processAgentName}</span>;
          }
        },
      },
      {
        key: 'processAgentRef',
        hideRow: (d) => !d?.processAgentRef,
        render: (d) => {
          if (isBdlUserOrAdmin) {
            return <span>{d.processAgentRef}</span>;
          }
        },
      },
      bdlOfficerAndSecretaryColumns(t, 'design', design),
    ],
  };
};

const DesignPage: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { id } = useParams<ExtractRouteParams<Routes.DesignPage, string>>();
  const designId = Number(id);
  const { design, designDataState, timeline } = useGetDesign(designId);
  const { designLinks } = useGetDesignLinks(design);
  const { data: events, tableProps: eventTableProps } =
    useGetDesignEvents(designId);
  const { getResourceBreadcrumbs } = useDesignBreadcrumbs();
  const images = design?.images ?? [];

  const links = useGetOfficialLinks<DesignWithImages>(
    getDesignOfficialLinks,
    design,
  );

  const tabs: TabsProps['items'] = [
    {
      key: 'events',
      label: t('table.title.event'),
      children: (
        <EventTable
          defaultShowAnnuities={true}
          editButtonEnabled={false}
          searchBarEnabled={false}
          dataSource={events}
          {...eventTableProps}
        />
      ),
    },
    {
      key: 'comments',
      label: t('notes.title'),
      children: <ResourceComment id={designId} resourceType="design" />,
    },
  ];

  return (
    <ResourceNotFound resourceExists={resourceExists(design, designDataState)}>
      <ConnectedPage
        translationKey="designDetails"
        breadcrumbs={getResourceBreadcrumbs(design)}
      >
        <Spacer direction="vertical" size="middle">
          <ErrorOverlay {...designDataState}>
            <Spacer direction="vertical" size="small">
              <HeaderTitles
                title={getCountryLabel(design?.country)}
                subtitle={design?.title ?? ''}
                dataTestId="designPage"
                family={{
                  id: design?.familyId,
                  route: Routes.DesignFamily,
                }}
              />
              <ResourceLinks resource={design} />
              <Timeline events={timeline} />
            </Spacer>

            <ResourcePanel
              description={{
                data: design,
                panels: getDesignDetails(
                  designLinks,
                  t,
                  design,
                  isBdlAdmin(user) || isBdlUser(user),
                ),
              }}
              resource="design"
              resourceId={designId}
              image={
                <Carousel
                  className="fullHeight"
                  urls={images}
                  loading={designDataState.loading}
                />
              }
              additionalActions={links && <OfficalLinkButton link={links[0]} />}
              additionalTabs={tabs}
            />
          </ErrorOverlay>
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default DesignPage;
