import ConnectedPage from 'business/user/helpers/connectedPage';
import Spacer from 'ui/spacer';
import { Input, Radio, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useOsrFilters } from 'business/resources/osr/services/useOsrFilters';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import NotFoundPage from 'ui/notFoundPage';
import OsrColorDescription from 'business/resources/osr/components/common/osrColorDescription';
import Flex from 'ui/flex';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetCountriesListQuery } from 'generated/graphql';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Button from 'ui/button';
import Table from 'ui/table';
import { DESIGN_RENEW_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import {
  handleRenewedDesignColor,
  handleToRenewDesignColor,
} from 'business/resources/osr/components/common/osrRowColors';
import { RangePicker } from 'ui/date/datePicker';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import DesignAnomaly from 'business/resources/osr/components/design/designAnomaly';
import DesignAcumass from 'business/resources/osr/components/design/designAcumass';
import { useGetDesignOsrData } from 'business/resources/osr/services/useGetDesignOsrData';

const OsrPage = () => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { filters, actions } = useOsrFilters('design');
  const { data: renewedData, tableProps: renewedTableProps } =
    useGetDesignOsrData({ ...filters }, 'renewed');

  const { data: toRenewData, tableProps: toRenewTableProps } =
    useGetDesignOsrData({ ...filters, ar: true }, 'toRenew');

  const { generateExcel, loading } = useGenerateExcelFile('design', filters);

  const { data } = useGetCountriesListQuery();

  const countries = data?.country ?? [];

  if (!user.osrAccess) {
    return <NotFoundPage />;
  }

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <ConnectedPage translationKey="osr">
      <Spacer direction="vertical" size="middle">
        <Spacer size="small" direction="vertical">
          <Flex justify="space-between">
            <h1 className="headerTitle">
              {t('pages.osr.title', { context: 'design' })}
            </h1>
          </Flex>

          <div style={{ width: '33%' }}>
            <Spacer direction="vertical" size="xsmall">
              <Input
                defaultValue={filters.companyRef}
                placeholder={t('pages.osr.filters.clientRef')}
                onChange={(e) => actions.onChangeCompanyRef(e.target.value)}
              />
            </Spacer>
          </div>
          <Flex>
            <Select
              showSearch
              mode="multiple"
              style={{ width: '33%' }}
              placeholder="Filtrer par pays"
              optionFilterProp="children"
              onChange={(value) => actions.onChangeCountries(value)}
              filterOption={filterOption}
              options={countries.map((c) => {
                return { label: c.labelFr, value: c.code };
              })}
            />
          </Flex>

          <div>
            <Tabs
              items={[
                {
                  key: 'RenewDesignCase',
                  label: t('pages.osr.title', { context: 'tabRenew' }),
                  children: (
                    <OsrTabs
                      actions={
                        <Spacer direction="vertical" size="small">
                          <Flex alignItems="center" justify="space-between">
                            <Flex column style={{ width: '33%' }}>
                              <RangePicker
                                allowClear
                                inputReadOnly={true}
                                defaultValue={[
                                  startOfYear(new Date()),
                                  endOfYear(new Date()),
                                ]}
                                onChange={(e) => {
                                  actions.onChangeRangeDate(
                                    e && e[0]
                                      ? new Date(endOfDay(e[0]))
                                      : e?.[0] ?? null,
                                    e && e[1]
                                      ? new Date(endOfDay(e[1]))
                                      : e?.[1] ?? null,
                                  );
                                }}
                              />
                            </Flex>

                            <Flex>
                              <Radio.Group
                                defaultValue={'all'}
                                onChange={(e) =>
                                  actions.onChangeOffice(e.target.value)
                                }
                              >
                                <Radio value={'all'}>
                                  {t('pages.osr.filters.office', {
                                    context: 'all',
                                  })}
                                </Radio>
                                <Radio value={'Europe'}>
                                  {t('pages.osr.filters.office', {
                                    context: 'europe',
                                  })}
                                </Radio>
                                <Radio value={'France'}>
                                  {t('pages.osr.filters.office', {
                                    context: 'france',
                                  })}
                                </Radio>
                                <Radio value={'InterDetail'}>
                                  {t('pages.osr.filters.office', {
                                    context: 'international',
                                  })}
                                </Radio>
                                <Radio value={'Others'}>
                                  {t('pages.osr.filters.office', {
                                    context: 'others',
                                  })}
                                </Radio>
                              </Radio.Group>
                            </Flex>
                          </Flex>

                          <Flex>
                            <Button
                              loading={loading}
                              type="primary"
                              onClick={() => generateExcel('RENEWS')}
                            >
                              Exporter les Renouvellements
                            </Button>
                          </Flex>
                        </Spacer>
                      }
                      tabsItems={[
                        {
                          key: 'toRenew',
                          label: `Dessins & modèles à renouveler (${toRenewTableProps.pagination?.total})`,
                          children: (
                            <Spacer direction="vertical" size="small">
                              <Flex justify="flex-end">
                                <OsrColorDescription
                                  colors={[
                                    {
                                      color: '#90EE90',
                                      label: 'Renouv. Possible',
                                    },
                                    {
                                      color: '#FFA502',
                                      label: 'Délai de grace',
                                    },
                                    {
                                      color: '#f27979',
                                      label: 'Hors délai',
                                    },
                                  ]}
                                />
                              </Flex>
                              <Table
                                name="designToRenew"
                                defaultColumns={DESIGN_RENEW_COLUMNS}
                                dataSource={toRenewData}
                                handleTableRowColor={handleToRenewDesignColor}
                                {...toRenewTableProps}
                              />
                            </Spacer>
                          ),
                        },
                        {
                          key: 'renewed',
                          label: `Dessins & modèles renouvelés (${renewedTableProps.pagination?.total})`,
                          children: (
                            <Spacer direction="vertical" size="small">
                              <Flex justify="flex-end">
                                <OsrColorDescription
                                  colors={[
                                    {
                                      color: '#c9ffb2',
                                      label: 'Complet',
                                    },
                                    {
                                      color: '#ffbf00',
                                      label: 'AR manquant',
                                    },
                                  ]}
                                />
                              </Flex>
                              <Table
                                name="designRenewed"
                                confirmationReceipt={{
                                  value: filters.ar ?? false,
                                  onChange: (e: boolean) =>
                                    actions.onChangeConfirmationReceipt(e),
                                }}
                                defaultColumns={DESIGN_RENEW_COLUMNS}
                                dataSource={renewedData}
                                handleTableRowColor={handleRenewedDesignColor}
                                {...renewedTableProps}
                              />
                            </Spacer>
                          ),
                        },
                      ]}
                    />
                  ),
                },
                {
                  key: 'Acumass',
                  label: 'Mise à jour Acumass',
                  children: <DesignAcumass filters={filters} />,
                },
                {
                  key: 'DesignAnomaly',
                  label: t('pages.osr.title', { context: 'tabAnomaly' }),
                  children: <DesignAnomaly filters={filters} />,
                },
              ]}
            />
          </div>
        </Spacer>
      </Spacer>
    </ConnectedPage>
  );
};

export default OsrPage;
