import { TableOutlined } from '@ant-design/icons';
import {
  generateFamilyPathByAssetType,
  generatePathByAssetType,
} from 'business/common/services';
import {
  getCountryColumn,
  getCountryKey,
} from 'technical/countriesTranslation/constants';
import { DesignOfFamily } from 'business/resources/design/services/types';
import { Link } from 'react-router-dom';
import {
  FAMILIES_TABLE_IMAGE_CELL_WIDTH,
  FAMILIES_TABLE_IMAGE_STYLE,
} from 'ui/imageDisplay/constants';
import { ColumnGenerationData } from 'ui/table/types';
import { Image } from 'antd';
import Loader from 'ui/loader';

export const getDesignFamilyColumns =
  (): ColumnGenerationData<DesignOfFamily> => [
    {
      key: 'title',
      translationKey: 'designName',
      ellipsis: true,
    },
    {
      ...getCountryColumn(),
      alwaysVisible: true,
      render: (_, design: DesignOfFamily) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={generatePathByAssetType('design', design.id)}
        >
          {design[getCountryKey()]}
        </Link>
      ),
    },
    {
      key: 'depositNumber',
    },
    { key: 'depositDate' },
    { key: 'registrationNumber' },
    { key: 'registrationDate' },
    {
      key: 'clientRef',
      ellipsis: true,
    },
    {
      key: 'clientRef2',
      ellipsis: true,
    },
    { key: 'bdlRef', ellipsis: true },
    { key: 'misRef', ellipsis: true },
    { key: 'status' },
  ];

export const bdlUserDesignAdvancedColumns: ColumnGenerationData<DesignOfFamily> =
  [
    {
      key: 'holder',
    },
    {
      key: 'effectiveHolder',
      ellipsis: true,
    },
    {
      key: 'title',
      ellipsis: true,
      translationKey: 'designName',
      render: (_, { id, title }) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={generatePathByAssetType('design', id)}
        >
          {title}
        </Link>
      ),
    },
    {
      key: 'image',
      translationKey: 'image',
      sorter: false,
      width: FAMILIES_TABLE_IMAGE_CELL_WIDTH,
      render: (_: any, { image }) => {
        if (image === 'loading') {
          return <Loader />;
        }
        if (image) {
          return (
            <Image src={image} style={{ ...FAMILIES_TABLE_IMAGE_STYLE }} />
          );
        }
      },
    },
    {
      ...getCountryColumn(),
      alwaysVisible: true,
    },
    {
      key: 'depositNumber',
      ellipsis: true,
    },
    { key: 'depositDate' },
    {
      key: 'registrationNumber',
      ellipsis: true,
    },
    {
      key: 'registrationDate',
      ellipsis: true,
    },
    { key: 'bdlRef', ellipsis: true },
    { key: 'misRef', ellipsis: true },
    {
      key: 'clientRef',
      ellipsis: true,
    },
    {
      key: 'clientRef2',
      ellipsis: true,
    },

    {
      key: 'bdlOfficer',
      checked: false,
      ellipsis: true,
    },
    {
      key: 'processAgentName',
      checked: false,
    },
    {
      key: 'processAgentRef',
      checked: false,
    },

    { key: 'status', ellipsis: true },
    { key: 'adjournedPublication', ellipsis: true },
    {
      key: 'familyId',
      translationKey: 'family',
      render: (_, { familyId }) => (
        <Link to={generateFamilyPathByAssetType('design', familyId)}>
          <TableOutlined />
        </Link>
      ),
    },
  ];

export const designAdvancedColumns: ColumnGenerationData<DesignOfFamily> = [
  {
    key: 'holder',
  },
  {
    key: 'effectiveHolder',
    ellipsis: true,
  },
  {
    key: 'title',
    ellipsis: true,
    translationKey: 'designName',
    render: (_, { id, title }) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType('design', id)}
      >
        {title}
      </Link>
    ),
  },
  {
    key: 'image',
    translationKey: 'image',
    sorter: false,
    width: FAMILIES_TABLE_IMAGE_CELL_WIDTH,
    render: (_: any, { image }) => {
      if (image === 'loading') {
        return <Loader />;
      }
      if (image) {
        return <Image src={image} style={{ ...FAMILIES_TABLE_IMAGE_STYLE }} />;
      }
    },
  },
  {
    ...getCountryColumn(),
    alwaysVisible: true,
  },
  {
    key: 'depositNumber',
    ellipsis: true,
  },
  { key: 'depositDate' },
  {
    key: 'registrationNumber',
    ellipsis: true,
  },
  {
    key: 'registrationDate',
    ellipsis: true,
  },
  { key: 'bdlRef', ellipsis: true },
  { key: 'misRef', ellipsis: true },
  {
    key: 'clientRef',
    ellipsis: true,
  },
  {
    key: 'clientRef2',
    ellipsis: true,
  },

  {
    key: 'bdlOfficer',
    checked: false,
    ellipsis: true,
  },

  { key: 'status', ellipsis: true },
  { key: 'adjournedPublication', ellipsis: true },
  {
    key: 'familyId',
    translationKey: 'family',
    render: (_, { familyId }) => (
      <Link to={generateFamilyPathByAssetType('design', familyId)}>
        <TableOutlined />
      </Link>
    ),
  },
];
