import useQueryForTable from 'technical/hooks/useQueryForTable';
import {
  GetDesignOsrDataListQuery,
  GetDesignOsrDataListQueryVariables,
  useGetDesignOsrDataListQuery,
} from 'generated/graphql';
import { GetDesignOsrResponse, OsrFilterState } from './types';

export const useGetDesignOsrData = (filters: OsrFilterState, type: string) => {
  return useQueryForTable<
    GetDesignOsrResponse,
    GetDesignOsrDataListQuery,
    GetDesignOsrDataListQueryVariables
  >({
    subStorageKey: type,
    useQuery: ({ limit, offset, orderBy, searchText }) => {
      return useGetDesignOsrDataListQuery({
        fetchPolicy: 'no-cache',
        variables: {
          limit,
          offset,
          orderBy,
          where: {
            type,
            dueDate: filters.dueDate,
            search: searchText,
            countryCode: filters.countryCode,
            companyRef: filters.companyRef,
            office: filters.office,
            ar: filters.ar,
          },
        },
      });
    },
    map: (res) => res?.getDesignOsrData?.resources ?? [],
    getTotal: (res) => res?.getDesignOsrData?.count ?? 0,
  });
};
