import {
  pad,
  pipe,
  removeDashAndAfter,
  takeAfterSlash,
} from 'technical/format/string';
import { isDefined } from 'technical/validation';
import { getOfficialLink, replaceLink } from './helpers';
import { links } from './constants';
import { DesignWithImages, OfficialLinkMethods } from './types';

const createEuropeanLink = (design: DesignWithImages) => {
  const { depositNumber, images } = design;
  if (!isDefined(depositNumber)) {
    return null;
  }
  // Rule extracted from V1
  if (depositNumber.includes('-') && !depositNumber.includes('/')) {
    return replaceLink(links.design.EM, depositNumber);
  }
  const modelId = pipe(removeDashAndAfter, pad(9))(depositNumber, design);
  const models = images.length ? images : [null];
  return models.map((_, i) =>
    replaceLink(links.design.EM, `${modelId}-${pad(4)((i + 1).toString())}`),
  );
};

const DESIGN_OFFICIAL_LINKS: OfficialLinkMethods<DesignWithImages> = {
  WO: getOfficialLink<DesignWithImages>()('depositNumber', takeAfterSlash)(
    links.design.WO,
  ),

  // For GB and EM (European Union) countryCode,  we need to create a link that direct to the european office website
  EM: (design: DesignWithImages) => createEuropeanLink(design),
  GB: (design: DesignWithImages) => createEuropeanLink(design),

  FR: ({ depositNumber, images }: DesignWithImages) => {
    if (!isDefined(depositNumber)) {
      return null;
    }
    if (depositNumber.includes('-')) {
      return replaceLink(links.design.FR, depositNumber);
    }
    const models = images.length ? images : [null];
    return models.map((_, i) =>
      replaceLink(
        links.design.FR,
        `${depositNumber}-${pad(3)((i + 1).toString())}`,
      ),
    );
  },

  CN: getOfficialLink<DesignWithImages>()('depositNumber')(links.design.CN),

  RU: getOfficialLink<DesignWithImages>()('registrationNumber')(
    links.design.RU,
  ),
};

export const getDesignOfficialLinks = (design: DesignWithImages) => {
  const countryCode = design.country?.code;
  if (!countryCode) {
    return null;
  }

  const method = DESIGN_OFFICIAL_LINKS[countryCode];
  return method ? method(design) : null;
};
