import Routes from 'business/router/routes';
import { isBefore } from 'date-fns';
import { generatePath } from 'react-router';
import { invalidCountries } from 'technical/countriesTranslation/constants';
import { isDefined } from 'technical/validation';
import { compareStatuses } from 'ui/map/mapUtils';
import { MapData, MapStatus } from 'ui/map/types';
import { ResourceMap, ResourceType } from './types';
import { removeAccent } from 'technical/format/string';

enum RawStatusType {
  EnCours = 'En cours',
  Engage = 'Engage',
  Valide = 'Valide',
  EnPreparation = 'En preparation',
  CessationDeMandat = 'Cessation de mandat',
  Abandonne = 'Abandonne',
  Rejete = 'Rejete',
  SansSuite = 'Sans suite',
  Retire = 'Retire',
  Expire = 'Expire',
  Dechu = 'Dechu',
  Revoque = 'Revoque',
}

export enum StatusType {
  Effective = 'effective',
  InPreparation = 'inPreparation',
  NonEffective = 'nonEffective',
  NotHandledByBdl = 'notHandledByBdl',
}

export const statusTypes: Record<string, StatusType> = {
  // StatusType.Effective
  [RawStatusType.EnCours]: StatusType.Effective,
  [RawStatusType.Engage]: StatusType.Effective,
  [RawStatusType.Valide]: StatusType.Effective,

  // StatusType.InPreparation
  [RawStatusType.EnPreparation]: StatusType.InPreparation,

  // StatusType.NonEffective
  [RawStatusType.CessationDeMandat]: StatusType.NonEffective,
  [RawStatusType.Abandonne]: StatusType.NonEffective,
  [RawStatusType.Rejete]: StatusType.NonEffective,
  [RawStatusType.SansSuite]: StatusType.NonEffective,
  [RawStatusType.Retire]: StatusType.NonEffective,
  [RawStatusType.Expire]: StatusType.NonEffective,
  [RawStatusType.Dechu]: StatusType.NonEffective,
  [RawStatusType.Revoque]: StatusType.NonEffective,

  // StatusType.NotHandledByBdl
  [StatusType.NotHandledByBdl]: StatusType.NotHandledByBdl,
};

export const getStatusesFromType = (type: StatusType) =>
  Object.keys(statusTypes).filter((status) => statusTypes[status] === type);

export const notValidStatuses = getStatusesFromType(StatusType.NonEffective);

export const isEffectiveConditionForQueries = {
  status: {
    _in: getStatusesFromType(StatusType.Effective),
  },
};

export const resourceExists = <T>(
  data: T | null | undefined,
  {
    loading,
    filtersActive = false,
  }: { loading: boolean; filtersActive?: boolean },
) => loading || filtersActive || isDefined(data);

export const isAValidCountry = (country?: string | null): country is string =>
  isDefined(country) && !invalidCountries.includes(country);
export const isResourceNotEffective = ({
  status,
  expirationDate,
}: ResourceMap) =>
  (isDefined(expirationDate) &&
    isBefore(Date.parse(expirationDate), new Date())) ||
  notValidStatuses.includes(removeAccent(status));

/**
 * Convert a list of resources to a map data object to display in Map component
 * @param resources resources to get the map data from
 * @param getStatusFunction optional function to get the status of a resource
 */
export const resourcesToMapData = <T extends ResourceMap>(
  resources: T[] | null | undefined,
  getStatusFunction?: (resource: T) => MapStatus,
): MapData => {
  if (!isDefined(resources)) {
    return {};
  }
  return resources.reduce<MapData>((acc, resource) => {
    if (isAValidCountry(resource.countryCode)) {
      const currentStatus = acc[resource.countryCode];
      const newStatus =
        getStatusFunction?.(resource) ??
        (isResourceNotEffective(resource)
          ? MapStatus.NotEffective
          : MapStatus.Valid);

      // If the current status is not defined or if the new status is more important than the current one
      if (
        !isDefined(currentStatus) ||
        compareStatuses(newStatus, currentStatus) < 0
      ) {
        acc[resource.countryCode] = newStatus;
      }
    }
    return acc;
  }, {});
};

const assetRoutes = {
  design: {
    assetRoute: Routes.Design,
    assetPageRoute: Routes.DesignPage,
    assetFamilyRoute: Routes.DesignFamily,
  },
  trademark: {
    assetRoute: Routes.Trademark,
    assetPageRoute: Routes.TrademarkPage,
    assetFamilyRoute: Routes.TrademarkFamily,
  },
  domain: {
    assetRoute: Routes.Domain,
    assetPageRoute: Routes.DomainPage,
    assetFamilyRoute: Routes.DomainFamily,
  },
  patent: {
    assetRoute: Routes.Patent,
    assetPageRoute: Routes.PatentPage,
    assetFamilyRoute: Routes.PatentFamily,
  },
};

export const generatePathByAssetType = (
  asset: ResourceType,
  id: number | null | undefined,
) => {
  const { assetRoute, assetPageRoute } = assetRoutes[asset];
  return id ? generatePath(assetPageRoute, { id }) : generatePath(assetRoute);
};

export const generateFamilyPathByAssetType = (
  asset: ResourceType,
  familyId?: string | null,
) => {
  const { assetFamilyRoute, assetRoute } = assetRoutes[asset];

  return familyId
    ? generatePath(assetFamilyRoute, {
        id: familyId,
      })
    : generatePath(assetRoute);
};
