import Button from 'ui/button';
import { OsrFilterState } from 'business/resources/osr/services/types';
import Table from 'ui/table';
import { TRADEMARK_RENEW_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetTrademarkOsrData } from 'business/resources/osr/services/useGetTrademarkOsrData';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';

interface ITrademarkAffidavits {
  filters: OsrFilterState;
}

const TrademarkAffidavits = (props: ITrademarkAffidavits) => {
  const { generateExcel } = useGenerateExcelFile('trademark', props.filters);
  const { data: affidavitData, tableProps: affidavitTableProps } =
    useGetTrademarkOsrData('AFFIDAVIT', {
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    });

  return (
    <OsrTabs
      tabsItems={[
        {
          key: 'affidavitsTable',
          label: `Liste des affidavits (${affidavitTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              searchBarEnabled={false}
              defaultColumns={TRADEMARK_RENEW_COLUMNS}
              name="affidavitTable"
              {...affidavitTableProps}
              dataSource={affidavitData}
            />
          ),
        },
      ]}
      actions={
        <Button type="primary" onClick={() => generateExcel('AFFIDAVIT')}>
          Exporter la liste des affidavits
        </Button>
      }
    />
  );
};

export default TrademarkAffidavits;
